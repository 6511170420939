/* eslint-disable @nx/enforce-module-boundaries */
import { useDisclosure, useToast } from '@mybridge/ui/hooks';
import { useMutation, useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flattenQueryResults, getNextPageFromURL } from 'v4/utils/utils';
import {
  getDegreeConnectionsByUserId,
  getUserConnectionsData,
  getConnectionsByUserId,
  getMutualConnections,
  getPublicSuggestions,
  getAllConnectionIDs,
  aggregatedPhotosCount,
  getPhotosOfYou,
  getYourPhotos,
  getCoverPhotos,
  getProfilePhotos,
  getYourVideos,
  getVideosOfYour,
} from 'v4/store/actions/connection.actions';
import {
  endorseSkill,
  endorseTestimonial,
  getPublicProfile,
  getPublicActivity
} from 'v4/store/actions/user-profile.actions';
import { useRouter } from 'next/router';
import { getSearchArticles } from 'v4/store/actions/global.data.actions';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';
export const PublicProfileContext = createContext({});

export const usePublicProfileContext = ({
  slug,
  profileRefetchEnabled = false,
  connectionsRefetchEnabled = true,
  refetchMutualConnectionsEnabled = false,
  refetchOtherUser2ndDegreeEnabled = false,
  refetchOtherUser3rdDegreeEnabled = false,
  refetchSuggestionsEnabled = false,
  myActvitiesRefetchEnabled = false,
}) => {
  const { userProfileInfo } = useSelector((state) => state.userProfile) ?? {};
  const { allDegreeConnections, allDegreeConnectionsArr, allConnectionIDs } =
    useSelector((state) => state.connection) ?? {};
  const { loggedIn } = useSelector((s) => s?.user) ?? {};
  const dispatch = useDispatch();
  const { t } = useTranslator();
  const {pathname, push} = useRouter();
  const causesDisc = useDisclosure();
  const interestsDisc = useDisclosure();
  const [searchQuery, setSearchQuery] = useState();
  const [activityCount, setActivityCount] = useState(0);
  const [photosOfYouCount, setPhotosOfYouCount] = useState(0);
  const [yourPhotosCount, setYourPhotosCount] = useState(0);
  const [coverPhotoCount, setCoverPhotosCount] = useState(0);
  const [profilePhotoCount, setProfilePhotoCount] = useState(0);
  const [yourVideosCount, setYourVideosCount] = useState(0);
  const [videosOfYouCount, setVideosOfYouCount] = useState(0);
  const [selectedSkill, setSelectedSkill] = useState();
  const [selectedTestimonial, setSelectedTestimonial] = useState();
  const [publicActiveTab, setPublicActiveTab] = useState(0);
  const [publicNetworkTab, setPublicNetworkTab] = useState(0);
  const toast = useToast({
    position: 'top',
  });

  /**
   * Public profile
   */
  const {
    data: publicProfile,
    refetch: refetchPublicProfile,
    isFetching: publicProfileFetching,
    isLoading: publicProfileLoading,
  } = useQuery({
    queryKey: ['public-profile', slug],
    queryFn: async ({ queryKey }) => {
      const resp = await dispatch(getPublicProfile(queryKey?.[1]));
      return resp?.payload;
    },
    refetchOnWindowFocus: false,
    enabled: profileRefetchEnabled,
  });


  /**
   * My activities
   */
  const {
    data: activityPosts_,
    refetch: refetchActivityPosts,
    hasNextPage: activityPostsHasNextPage,
    fetchNextPage: activityPostsFetchNextPage,
    isFetching: activityPostsIsFetching,
    isLoading: activityPostsIsLoading,
  } = useInfiniteQuery({
    queryKey: ['myActivity', publicProfile?.id],
    queryFn: async ({ pageParam, queryKey }) => {
      const resp = await dispatch(
        getPublicActivity({ page: pageParam, pageSize: 5, userId:queryKey?.[1] })
      );
      setActivityCount(resp?.payload?.count);
      return resp?.payload;
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: myActvitiesRefetchEnabled,
  });

  const {
    data: aggregatedPhotosData,
    refetch: refetchAgregatedPhotos,
    isFatching: fatchStatusAggregatedPhotos,
  } = useQuery({
    queryKey: ['aggregatedPhotosData'],
    queryFn: async ({ user, company }) => {
      const resp = await dispatch(
        aggregatedPhotosCount({ user: publicProfile.slug, company })
      );
      return resp?.payload ?? [];
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: photosOfYou_,
    refetch: refetchPhotosOfyou,
    hasNextPage: photosOfYouHasNextPage,
    fetchNextPage: photosOfYouFetchNextPage,
    isFetching: phototsOfYouIsFetching,
  } = useInfiniteQuery({
    queryKey: ['photosOfYou'],
    queryFn: async ({ slug, pageParam }) => {
      const resp = await dispatch(
        getPhotosOfYou({
          page: pageParam || 1,
          pageSize: 10,
          slug: publicProfile.slug,
        })
      );
      setPhotosOfYouCount(resp?.payload?.count);
      return resp?.payload;
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: yourPhotos_,
    refetch: refetchYourPhotos,
    hasNextPage: yourPhotosHasNextPage,
    fetchNextPage: yourPhotosFetchNextPage,
    isFetching: yourPhotosIsFetching,
  } = useInfiniteQuery({
    queryKey: ['yourPhotos'],
    queryFn: async ({ user, pageParam }) => {
      const resp = await dispatch(
        getYourPhotos({
          page: pageParam || 1,
          pageSize: 10,
          user: publicProfile.slug,
        })
      );
      setYourPhotosCount(resp?.payload?.count);
      return resp?.payload;
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: coverPhotos_,
    refetch: refetchCoverPhotos,
    hasNextPage: coverPhotosHasNextPage,
    fetchNextPage: coverPhotosFetchNextPage,
    isFetching: coverPhotosIsFetching,
  } = useInfiniteQuery({
    queryKey: ['coverPhotos'],
    queryFn: async ({ user, pageParam }) => {
      const resp = await dispatch(
        getCoverPhotos({
          page: pageParam || 1,
          pageSize: 10,
          user: publicProfile.slug,
        })
      );
      setCoverPhotosCount(resp?.payload?.count);
      return resp?.payload;
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: profilePhotos_,
    refetch: refetchProfilePhotos,
    hasNextPage: profilePhotosHasNextPage,
    fetchNextPage: profilePhotosFetchNextPage,
    isFetching: profilePhotosIsFetching,
  } = useInfiniteQuery({
    queryKey: ['profilePhotos'],
    queryFn: async ({ user, pageParam }) => {
      const resp = await dispatch(
        getProfilePhotos({
          page: pageParam || 1,
          pageSize: 10,
          user: publicProfile.slug,
        })
      );
      setProfilePhotoCount(resp?.payload?.count);
      return resp?.payload;
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: yourVideos_,
    refetch: refetchyourVideos,
    hasNextPage: yourVideosHasNextPage,
    fetchNextPage: yourVideosFetchNextPage,
    isFetching: yourVideosIsFetching,
  } = useInfiniteQuery({
    queryKey: ['yourVideos'],
    queryFn: async ({ user, pageParam }) => {
      const resp = await dispatch(
        getYourVideos({
          page: pageParam || 1,
          pageSize: 10,
          user: publicProfile.slug,
        })
      );
      setYourVideosCount(resp?.payload?.count);
      return resp?.payload;
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: videosOfYou_,
    refetch: refetchvideosOfYou,
    hasNextPage: videosOfYouHasNextPage,
    fetchNextPage: videosOfYouFetchNextPage,
    isFetching: videosOfYouIsFetching,
  } = useInfiniteQuery({
    queryKey: ['videosOfYou'],
    queryFn: async ({ user, pageParam }) => {
      const resp = await dispatch(
        getVideosOfYour({
          page: pageParam || 1,
          pageSize: 10,
          user: publicProfile.slug,
        })
      );
      setVideosOfYouCount(resp?.payload?.count);
      return resp?.payload;
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: false,
  });


  /**
   * My articles
   */
  const {
    data: publicArticles_,
    refetch: refetchPublicArticles,
    hasNextPage: publicArticlesHasNextPage,
    fetchNextPage: publicArticlesFetchNextPage,
    isFetching: publicArticlesIsFetching,
    isLoading: publicArticlesIsLoading,
  } = useInfiniteQuery({
    queryKey: ['publicArticles', publicProfile?.id],
    queryFn: async ({ pageParam, queryKey }) => {
      const resp = await dispatch(
        getSearchArticles({ page: pageParam, pageSize: 5, member_id:queryKey?.[1], add_to_profile:true })
      );
      return resp?.payload;
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: myActvitiesRefetchEnabled,
  });

  /**
   * User connections
   */
  const {
    data: connections,
    refetch: refetchConnections,
    isFetching: connectionsFetching,
    isLoading:connectionsIsLoading,
    fetchNextPage: connectionsFetchNextPage,
    hasNextPage: connectionsHasNextPage,
  } = useInfiniteQuery({
    // queryKey: ['connections', publicProfile?.id],
    // queryFn: async ({ pageParam, queryKey }) => {
    //   const resp = await dispatch(getConnectionsByUserId(queryKey?.[1]));
    //   return resp?.payload ?? [];
    // },
    queryKey: [
      'connections',
      { id: publicProfile?.id, search: searchQuery },
    ],
    queryFn: async ({ pageParam, queryKey }) => {
      const { id, search } = queryKey?.[1] ?? {};
      // console.log('publicProfile?.id', publicProfile?.id)
      const resp = await dispatch(
        getDegreeConnectionsByUserId({
          userId: id,
          degree: 1,
          query: search,
          pageParam,
        })
      );
      return resp?.payload ?? [];
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: connectionsRefetchEnabled,
  });

  /**
   * Mutual Connections
   */
  const {
    data: mutualConnections,
    isFetching: mutualConnectionsIsFetching,
    isLoading: mutualConnectionsIsLoading,
    refetch: refetchMutualConnections,
    fetchNextPage: mutualConnectionsFetchNextPage,
    hasNextPage: mutualConnectionsHasNextPage,
  } = useInfiniteQuery({
    queryKey: [
      'mutual-connections',
      { id: publicProfile?.id, search: searchQuery },
    ],
    queryFn: async ({ pageParam, queryKey }) => {
      const { id, search } = queryKey?.[1] ?? {};
      const resp = await dispatch(
        getMutualConnections({
          userId: id,
          query: search,
          pageParam,
        })
      );
      return resp?.payload ?? [];
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: refetchMutualConnectionsEnabled,
  });

  /**
   * 2nd degree
   */
  const {
    data: otherUserSecondDegreeConnections,
    isFetching: otherUserSecondDegreeConnectionsIsFetching,
    isLoading: otherUserSecondDegreeConnectionsIsLoading,
    refetch: refetchOtherUserSecondDegreeConnections,
    fetchNextPage: otherUserSecondDegreeConnectionsFetchNextPage,
    hasNextPage: otherUserSecondDegreeConnectionsHasNextPage,
  } = useInfiniteQuery({
    queryKey: [
      'second-degree-connections',
      { id: publicProfile?.id, search: searchQuery },
    ],
    queryFn: async ({ pageParam, queryKey }) => {
      const { id, search } = queryKey?.[1] ?? {};
      const resp = await dispatch(
        getDegreeConnectionsByUserId({
          userId: id,
          degree: 2,
          query: search,
          pageParam,
        })
      );
      return resp?.payload ?? [];
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: refetchOtherUser2ndDegreeEnabled,
  });

  /**
   * 3rd degree
   */
  const {
    data: otherUserThirdDegreeConnections,
    refetch: refetchOtherUserThirdDegreeConnections,
    isFetching: otherUserThirdDegreeConnectionsIsFetching,
    isLoading: otherUserThirdDegreeConnectionsIsLoading,
    fetchNextPage: otherUserThirdDegreeConnectionsFetchNextPage,
    hasNextPage: otherUserThirdDegreeConnectionsHasNextPage,
  } = useInfiniteQuery({
    queryKey: ['third-degree-connections', { id: publicProfile?.id, search: searchQuery },],
    queryFn: async ({ pageParam, queryKey }) => {
      const { id, search } = queryKey?.[1] ?? {};
      const resp = await dispatch(
        getDegreeConnectionsByUserId({
          userId: id,
          degree: 3,
          pageParam,
        })
      );
      return resp?.payload ?? [];
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: refetchOtherUser3rdDegreeEnabled,
  });

  /**
   * skill endorsment mutation
   */
  const {
    data: endorsmentResult,
    isLoading: endorsmentLoading,
    mutateAsync: endorsmentAsync,
  } = useMutation({
    mutationKey: ['profile-endorse-skill'],
    mutationFn: async (payload) => {
      return (await dispatch(endorseSkill(payload)))?.payload;
    },
  });

  /**
   * testimonial endorsment mutation
   */
  const {
    data: testimonialEndorsmentResult,
    isLoading: testimonialEndorsmentLoading,
    mutateAsync: testimonialEndorsmentAsync,
  } = useMutation({
    mutationKey: ['profile-endorse-testimonial'],
    mutationFn: async (payload) => {
      return (await dispatch(endorseTestimonial(payload)))?.payload;
    },
  });

  /**
   * Suggestions
   */
  const {
    data: suggestions,
    refetch: refetchSuggestions,
    isFetching: suggestionsIsFetching,
    isLoading: suggestionsIsLoading,
  } = useQuery({
    queryKey: ['suggestions', publicProfile?.id],
    queryFn: async ({ queryKey }) => {
      const resp = await dispatch(
        getPublicSuggestions({type:'[university,follow,degree,role,industry,location]', userId:queryKey?.[1]})
      );
      return resp?.payload ?? {};
    },
    refetchOnWindowFocus: false,
    enabled: refetchSuggestionsEnabled,
  });

  // =========== util methods

  const entityHasStartDates = (entity) => {
    return entity?.start_month && entity?.start_year;
  };
  const entityHasEndDates = (entity) => {
    return entity?.end_month && entity?.end_year;
  };

  const {
    users_experiences: experiences,
    user_skills: skills,
    user_posts: activityPosts,
    users_educations: education,
    profile_milestones: milestones,
    profile_certificates: certificates,
    testimonial_asker: testimonials,
  } = publicProfile ?? {};

  const endorseSkill_ = async (skill) => {
    try {
      const resp = await endorsmentAsync(skill?.id);
      const { status_code } = resp ?? {};
      if (status_code >= 400) {
        toast({
          title: 'Error',
          description: t('toast.unableToAgree') || 'Unable to agree right now! Please try again later.',
          status: 'error',
        });
        return;
      }
      toast({
        title: t('toast.skillEndorsed') || 'Skill endorsed!',
        status: 'success',
      });
      refetchPublicProfile?.();
    } catch (e) {
      toast({
        title: 'Error',
        description: t('toast.unableToAgree') || 'Unable to agree right now! Please try again later.',
        status: 'error',
      });
    }
  };

  const endorseTestimonial_ = async (test) => {
    try {
      const resp = await testimonialEndorsmentAsync(test?.id);
      const { status_code } = resp ?? {};
      if (status_code >= 400) {
        toast({
          title: 'Error',
          description: t('toast.unableToAgree') || 'Unable to agree right now! Please try again later.',
          status: 'error',
        });
        return;
      }
      toast({
        title: t('toast.testimonialEndorsed') || 'Testimonial endorsed!',
        status: 'success',
      });
      refetchPublicProfile?.();
    } catch (e) {
      toast({
        title: 'Error',
        description: t('toast.unableToAgree') || 'Unable to agree right now! Please try again later.',
        status: 'error',
      });
    }
  };

  const handleTabsChangeForNetwork = (e) => {
    if(publicActiveTab === 0){
      setPublicActiveTab(1);
      setPublicNetworkTab(e);
      
    } else {
      setPublicNetworkTab(e);
    }
    
  }
  const { follow = [], university = [], degree = [], role = [], industry = [], location = [] } = suggestions ?? {};
  const allSuggestions = [...university, ...role, ...industry, ...degree, ...location, ...follow];

  const {
    email,
    mobile_number,
    language,
    interested_in,
    causes_you_care,
    profile_intro,
    profile_headline,
    website,
    social_links
  } = publicProfile ?? {};

  const {
    handshake_requests,
    handshake_received,
    circle,
    following,
    company_following_ids,
  } = allConnectionIDs ?? {};

  const isHandshaked = (user) =>
    handshake_requests?.includes?.(user?.id) ||
    circle?.includes?.(user?.id) ||
    handshake_received?.includes?.(user?.id);

  const isFriend = (user) => circle?.includes?.(user?.id);

  const isHandshakeRequested = (user) =>
    handshake_requests?.includes?.(user?.id) ||
    handshake_received?.includes?.(user?.id);

  const isFollowed = (user) => following?.includes?.(user?.id);

  const isCompanyFollowed = (user) =>
    company_following_ids?.includes?.(user?.id);

  const hasPersonalInfo =
    email?.length || mobile_number?.length || language?.length;


  console.log('publicProfile', publicProfile);
  

  const hasInterestedIn = interested_in?.length;
  const hasCauses = causes_you_care?.length;
  const hasWebsite = website?.length;
  const hasLinks = social_links?.length;

  const hasIntro = profile_intro?.length || profile_headline?.length;
  const hasActivity = activityPosts?.length;

  const activityPostsData = flattenQueryResults(activityPosts_);

  const otherUserThirdDegreeConnections_ = flattenQueryResults(
    otherUserThirdDegreeConnections
  );
  const otherUserSecondDegreeConnections_ = flattenQueryResults(
    otherUserSecondDegreeConnections
  );
  const mutualConnections_ = flattenQueryResults(
    mutualConnections
  );
  const connections_ = flattenQueryResults(
    connections
  );
  const publicArticles = flattenQueryResults(
    publicArticles_
  );

  const photosOfYou = flattenQueryResults(photosOfYou_);
  const yourPhotos = flattenQueryResults(yourPhotos_);
  const coverPhotos = flattenQueryResults(coverPhotos_);
  const profilePhotos = flattenQueryResults(profilePhotos_);
  const yourVideos = flattenQueryResults(yourVideos_);
  const videosOfYou = flattenQueryResults(videosOfYou_);

  const hasPhotosOfYou = photosOfYou?.length > 0 ? true : false;
  const hasYourPhotos = yourPhotos?.length > 0 ? true : false;
  const hasCoverPhotos = coverPhotos?.length > 0 ? true : false;
  const hasProfilePhotos = profilePhotos?.length > 0 ? true : false;
  const hasYourVideos = yourVideos?.length > 0 ? true : false;
  const hasVideosOfYou = videosOfYou?.length > 0 ? true : false;


  return {
    hasIntro,
    hasActivity,
    hasPersonalInfo,
    hasWebsite,
    hasInterestedIn,
    hasCauses,
    hasLinks,
    causesDisc,
    interestsDisc,
    userProfileInfo,
    allDegreeConnections,
    allDegreeConnectionsArr,
    publicProfile,
    entityHasEndDates,
    entityHasStartDates,
    refetchPublicProfile,
    publicProfileFetching,
    publicProfileLoading,
    experiences,
    skills,
    testimonials,
    activityPosts,
    education,
    milestones,
    certificates,

    connections:connections_,
    refetchConnections,
    connectionsFetching,
    connectionsIsLoading,
    connectionsFetchNextPage,
    connectionsHasNextPage,
    connectionsCount:connections?.pages?.[0]?.count,

    endorsmentResult,
    endorsmentLoading,
    endorseSkill: endorseSkill_,
    endorseTestimonial: endorseTestimonial_,
    testimonialEndorsmentResult,
    testimonialEndorsmentLoading,
    testimonialEndorsmentAsync,
    loggedIn,
    selectedSkill,
    setSelectedSkill,
    selectedTestimonial,
    setSelectedTestimonial,
    publicActiveTab,
    setPublicActiveTab,
    publicNetworkTab,
    setPublicNetworkTab,


    mutualConnections:mutualConnections_,
    refetchMutualConnections,
    mutualConnectionsIsFetching,
    mutualConnectionsIsLoading,
    mutualConnectionsFetchNextPage,
    mutualConnectionsHasNextPage,
    mutualConnectionsCount:mutualConnections?.pages?.[0]?.count,


    otherUserThirdDegreeConnections:otherUserThirdDegreeConnections_,
    refetchOtherUserThirdDegreeConnections,
    otherUserThirdDegreeConnectionsIsFetching,
    otherUserThirdDegreeConnectionsIsLoading,
    otherUserThirdDegreeConnectionsFetchNextPage,
    otherUserThirdDegreeConnectionsHasNextPage,
    otherUserThirdDegreeConnectionsCount:otherUserThirdDegreeConnections?.pages?.[0]?.count,


    otherUserSecondDegreeConnections:otherUserSecondDegreeConnections_,
    refetchOtherUserSecondDegreeConnections,
    otherUserSecondDegreeConnectionsIsFetching,
    otherUserSecondDegreeConnectionsIsLoading,
    otherUserSecondDegreeConnectionsFetchNextPage,
    otherUserSecondDegreeConnectionsHasNextPage,
    otherUserSecondDegreeConnectionsCount:otherUserSecondDegreeConnections?.pages?.[0]?.count,

    handleTabsChangeForNetwork,

    suggestions,
    refetchSuggestions,
    suggestionsIsFetching,
    suggestionsIsLoading,
    allSuggestions,

    activityPostsData,
    refetchActivityPosts,
    activityPostsHasNextPage,
    activityPostsFetchNextPage,
    activityPostsIsFetching,
    activityCount,
    activityPostsIsLoading,

    isHandshaked,
    isHandshakeRequested,
    isFollowed,
    isFriend,

    aggregatedPhotosData,
    refetchAgregatedPhotos,
    fatchStatusAggregatedPhotos,
    photosOfYouCount,
    hasPhotosOfYou,
    photosOfYou,
    refetchPhotosOfyou,
    photosOfYouHasNextPage,
    photosOfYouFetchNextPage,
    phototsOfYouIsFetching,
    yourPhotos,
    yourPhotosCount,
    hasYourPhotos,
    refetchYourPhotos,
    yourPhotosHasNextPage,
    yourPhotosFetchNextPage,
    yourPhotosIsFetching,
    coverPhotos,
    hasCoverPhotos,
    coverPhotoCount,
    refetchCoverPhotos,
    coverPhotosHasNextPage,
    coverPhotosFetchNextPage,
    coverPhotosIsFetching,
    profilePhotos,
    hasProfilePhotos,
    profilePhotoCount,
    refetchProfilePhotos,
    profilePhotosHasNextPage,
    profilePhotosFetchNextPage,
    profilePhotosIsFetching,
    yourVideos,
    hasYourVideos,
    yourVideosCount,
    refetchyourVideos,
    yourVideosHasNextPage,
    yourVideosFetchNextPage,
    yourVideosIsFetching,
    videosOfYou,
    hasVideosOfYou,
    videosOfYouCount,
    refetchvideosOfYou,
    videosOfYouHasNextPage,
    videosOfYouFetchNextPage,
    videosOfYouIsFetching,



    publicArticles,
    refetchPublicArticles,
    publicArticlesHasNextPage,
    publicArticlesFetchNextPage,
    publicArticlesIsFetching,
    publicArticlesIsLoading,
    publicArticlesCount:publicArticles_?.pages?.length*10,
  };
};
